'use client';

import { useEffect } from 'react';
import { useAppContext } from '@/lib/context';
import { cookieManager } from '@vaimo-int/one-trust';
import MagentoStorefrontEvents from '@adobe/magento-storefront-events-sdk/dist/types/MagentoStorefrontEvents';
import '@adobe/magento-storefront-event-collector';

const InitProductRecsEventsClient = () => {
    const [{ storeConfig }] = useAppContext();

    const setInitContext = () => {
        const config = {
            baseCurrencyCode: storeConfig.baseCurrencyCode,
            environment: 'Production',
            environmentId: storeConfig.adobeProductRecsEnvId,
            storeCode: storeConfig.storeGroupCode,
            storeId: storeConfig.storeGroupId,
            storeName: storeConfig.storeGroupName,
            storeUrl: globalThis.location.origin,
            storeViewCode: storeConfig.storeCode,
            storeViewCurrencyCode: storeConfig.baseCurrencyCode,
            storeViewId: storeConfig.storeId,
            storeViewName: storeConfig.storeName,
            storefrontTemplate: 'Other',
            websiteId: storeConfig.websiteId,
            websiteName: storeConfig.websiteName,
        };

        globalThis.window.adobeDataLayer.push({ storefrontInstanceContext: config });
    };

    const init = async () => {
        setInitContext();
        let mse = globalThis.window.magentoStorefrontEvents;

        if (!mse) {
            globalThis.window.adobeDataLayer.push({ eventForwardingContext: { aep: false, commerce: true } });
            // IMPORTANT! This is a workaround way to load the script,
            // to make storefront events being sent to the adobe service from adobeDataLayer.
            mse = (await import('@adobe/magento-storefront-events-sdk')) as unknown as MagentoStorefrontEvents;
            globalThis.window.magentoStorefrontEvents = mse;
        }

        // We create magentoStorefrontEventsQueue to add events with the help of MSE, before the package is initialized above.
        // Another solution is to add events directly to adobeDataLayer
        if (globalThis.window.magentoStorefrontEventsQueue) {
            globalThis.window.magentoStorefrontEventsQueue.forEach((func) => func());
            globalThis.window.magentoStorefrontEventsQueue = undefined;
        }
    };

    useEffect(() => {
        cookieManager.executeIfAllowed(() => {
            init();
        }, cookieManager.PrivacyGroupEnum.TARGETING);
    }, [storeConfig.storeCode]);

    return null;
};

export default InitProductRecsEventsClient;
